import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";

function App() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [api, setApi] = useState({});

  useEffect(() => {
    fetch(apiUrl)
      .then(resp => resp.json())
      .then(json => setApi(json))
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          API URL: <a href={apiUrl}>{apiUrl}</a>
        </p>

        <code>
          {JSON.stringify(api)}
        </code>
      </header>
    </div>
  );
}

export default App;
